const uL = navigator.language || navigator.languages[0];
let lC = uL.split('-')[0];
console.log(lC);

const uA = navigator.userAgent;

function getDeviceType() {
    if (uA.match(/Android/i) || uA.match(/webOS/i) || uA.match(/iPhone/i) || uA.match(/iPad/i) || uA.match(/iPod/i) || uA.match(/BlackBerry/i) || uA.match(/Windows Phone/i)) {
        return "m";
    } else {
        return "d"
    }
}
let myElement = document.querySelector('page');
let deviceType = getDeviceType(); // Функция для определения типа устройства
if (deviceType === 'm') {
    myElement.classList.add('mobile');
} else if (deviceType === 'd') {
    myElement.classList.add('desctop');
}
console.log(myElement);
if (!['en','uk', 'ru'].find(e => e.toLowerCase() === lC.toLowerCase())) lC = "en";
console.log(lC, `/dicts/${lC}.json`);
fetch(`/dicts/${lC}.json`)
    .then(response => {
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
    }).then(dictionary => {
        console.log(dictionary);
        Object.keys(dictionary).forEach(key => {
            if (document.getElementById(key)) document.getElementById(key).innerHTML = dictionary[key]
        })
/*
        fetch('/path/to/other/page.html')
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.text();
            })
            .then(html => {
                // сохраните html в переменной или в localStorage для последующего использования
                // затем сделайте кнопку активной
                document.querySelector('#otherPageBtn').disabled = false;
            });

        // фоновая загрузка других словарей
        otherLangs.forEach(lang => {
            fetch(`/path/to/dictionaries/${lang}.json`)
                .then(response => {
                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }
                    return response.json();
                })
                .then(dictionary => {
                    // сохраните словарь в переменной или в localStorage для последующего использования
                    // затем добавьте флажок страны
                    var img = document.createElement('img');
                    img.src = `/path/to/flags/${lang}.png`;
                    img.alt = lang;
                    document.body.appendChild(img);
                });
        });*/
    })
    .catch(e => {
        console.error('There was a problem with the fetch operation: ' + e.message);
    });
